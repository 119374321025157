import {
  AfterContentInit,
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FORM_CLIENT,
  FormsClient,
  ReactiveFormComponentInterface,
} from '@azlabsjs/ngx-smart-form';
import { AppUIStateProvider } from 'src/app/views/partial/ui-state/core';
import { SearcherService } from './searcher.service';
import {
  Observable,
  Subject,
  catchError,
  map,
  of,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { NotificationService } from 'src/app/bloc/utils/notification.service';
import { FormConfigInterface } from '@azlabsjs/smart-form-core';

@Component({
  selector: 'app-searcher',
  templateUrl: './searcher.component.html',
  styleUrls: ['./searcher.component.css'],
})
export class SearcherComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterContentInit
{
  @Input() public formId: number = 0;
  @Input() public query!: any;
  @Input() public data!: any;
  @Input() set url(value: string) {
    this.searcher$.setUrl(value);
  }
  @Input() public autoSubmit: boolean = true;
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() readyState: EventEmitter<any> = new EventEmitter();
  @Output() performingAction: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('formvalue', { static: false })
  formvalue!: ReactiveFormComponentInterface;

  public form$!: Observable<FormConfigInterface>;

  private destroy$ = new Subject<void>();

  constructor(
    @Inject(FORM_CLIENT) private formsClient: FormsClient,
    private UIState: AppUIStateProvider,
    private searcher$: SearcherService,
    private notification: NotificationService
  ) {}

  ngAfterContentInit(): void {
    this.form$ = this.formsClient.get(this.formId);
  }

  ngAfterViewInit(): void {
    this.setState();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit(): void {
    if (this.data) {
      setTimeout(() => {
        this.formvalue.formGroup.patchValue(this.data);
      }, 10);
    }
  }

  setState() {
    this.UIState.uiState
      .pipe(takeUntil(this.destroy$))
      .subscribe((state) => this.performingAction.emit(state.performingAction));
  }

  onSubmit(event: any) {
    if (this.autoSubmit) {
      this.UIState.startAction();
      this.searcher$
        .search({ event, ...this.query })
        .pipe(
          takeUntil(this.destroy$),
          catchError((err) => {
            this.notification.error(err);
            return throwError(() => err);
          }),
          map((res: any) => res?.data)
        )
        .subscribe({
          next: (data) => {
            this.submit.emit(data);
            this.UIState.endAction();
          },
        });
    } else {
      this.submit.emit(event);
    }
  }

  onReadyState(event: any) {
    this.readyState.emit(event);
  }
}
