import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './bloc/page-not-found/page-not-found.component';
import { AuthGuardService } from './views/login/guards';
import { routeDefinitions } from './views/dashboard/routesDefinitions';

const routes: Routes = [
  {
    path: '',
    redirectTo: routeDefinitions.dashboard,
    pathMatch: 'full',
  },
  {
    path: routeDefinitions.dashboard,
    loadChildren: () =>
      import('./views/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: routeDefinitions.login,
    loadChildren: () =>
      import('./views/login/login.module').then((m) => m.LoginModule),
    data: {
      path: routeDefinitions.dashboard,
      loadChildren: () =>
        import('./views/dashboard/dashboard.module').then(
          (m) => m.DashboardModule
        ),
      authorizations: [],
      moduleName: environment.module.name,
      logoAssetPath: environment.company.appLogo,
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
      useHash: true
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
