import { Component } from '@angular/core';
import { AppUIStateProvider } from './views/partial/ui-state/core';
import { map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'cooperative-gsc';
  showComponentLoadingDirective = false;

  uiState$ = this.uiState.uiState.pipe(
    map((state) => ({
      ...state,

      hidden:
        state.performingAction ||
        typeof state.status === 'undefined' ||
        state.status === null,
    }))
  );

  constructor(private uiState: AppUIStateProvider) {}
}
