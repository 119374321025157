import { Pipe, PipeTransform } from '@angular/core';
import { useQuery } from '@azlabsjs/ngx-query';
import { environment } from 'src/environments/environment';
import { filter, map } from 'rxjs';
import { JSObject } from '@azlabsjs/js-object';
import { Querycacher } from '../helpers/Querycacher';

@Pipe({
  name: 'member',
})
export class MemberPipe implements PipeTransform {
  constructor(private memberQuery: Querycacher) {}
  transform(
    member_id: unknown,
    params?: Record<string, any> | string,
    sep: string = ' ',
    ...key: string[]
  ) {
    return useQuery(
      this.memberQuery,
      `${environment.forms.clientHost}/members/${member_id}${
        typeof params === 'string' ? `?${params}` : ''
      }`,
      typeof params === 'object' && params !== null ? { params } : {}
    ).pipe(
      filter((value) => typeof value !== undefined && value !== null),
      map((value: Record<string, any>) =>
        key
          ? key
            .reduce((carr, curr) => {
                const prop = JSObject.getProperty(value, curr);
                if (prop) {
                  carr.push(prop);
                }
                return carr;
              }, [] as any[])
              .join(sep)
          : value
      )
    );
  }
}
