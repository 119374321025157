import { NgModule } from '@angular/core';
import { ContentHeaderComponent } from './content-header/content-header.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { RouterModule } from '@angular/router';
import { CardsComponent } from './cards/cards.component';
import { SearcherComponent } from './searcher/searcher.component';
import { NgxSmartFormModule } from '@azlabsjs/ngx-smart-form';

@NgModule({
  declarations: [
    ContentHeaderComponent,
    PageNotFoundComponent,
    CardsComponent,
    SearcherComponent,
  ],
  imports: [CommonModule, RouterModule, ClarityModule, NgxSmartFormModule],
  exports: [
    ContentHeaderComponent,
    PageNotFoundComponent,
    CardsComponent,
    SearcherComponent,
  ],
})
export class BlocsModule {}
