import { NgModule } from '@angular/core';
import { ClarityModule } from '@clr/angular';
import { PipesModule } from './views/partial/pipes';
import { ProgressBarModule } from './views/partial/progress-bar';
import { UIStateComponentsModule } from './views/partial/ui-state';
import { TopBarModule } from './views/partial/topbar';
import { SidebarModule } from './views/partial/sidebar';
import { NgxSmartFormModule } from '@azlabsjs/ngx-smart-form';
import { ClrSmartGridModule } from './views/partial/clr-smart-grid';
import { DatagridHeaderModule } from './views/partial/datgrid-header';
import { NgxClrSmartGridModule } from '@azlabsjs/ngx-clr-smart-grid';
import { ReactiveFormsModule } from '@angular/forms';
import { ChartjsModule } from '@ctrl/ngx-chartjs';
import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart,
  DoughnutController,
  Legend,
  LineController,
  LinearScale,
  PieController,
  PolarAreaController,
  RadarController,
  ScatterController,
  Title,
  Tooltip,
} from 'chart.js';
import { BlocsModule } from './bloc/blocs.module';
Chart.register(
  BarController,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  DoughnutController,
  LineController,
  PolarAreaController,
  ScatterController,
  RadarController,
  BubbleController,
  PieController
);
import { NgxAzlCacheModule } from '@azlabsjs/ngx-azl-cache';

@NgModule({
  declarations: [],
  exports: [
    ReactiveFormsModule,
    ClarityModule,
    PipesModule,
    ProgressBarModule,
    UIStateComponentsModule,
    TopBarModule,
    SidebarModule,
    NgxSmartFormModule,
    ClrSmartGridModule,
    DatagridHeaderModule,
    NgxClrSmartGridModule,
    ChartjsModule,
    BlocsModule,
    NgxAzlCacheModule,
  ],
  providers: [],
  bootstrap: [],
})
export class SharedModule {}
