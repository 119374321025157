export const config = 'config';
export const routeDefinitions = {
  home: 'home',
  dashboard: 'dashboard',
  login: 'login',
  search: 'search',
  credits: 'credits',
  requests: 'requests',
  members: 'members',
  config: 'config',
  refunds: 'refunds',
  payment_sequences: 'payment-sequences',
  payment_modalities: 'payment-modalities',
  status_management: 'status-manegement',
  assurances: 'assurances',
  credit_types: 'credit-types',
  commitment_details: 'commitment-details',
  credit_insurances: 'credit-insurances',
  reports: 'reports',
  approved_credits: 'approved-credits',
  expired_credits: 'expired-credits',
  classification: 'classification',
  overdue_amounts: 'overdue-amounts',
  logs: 'logs'
};
