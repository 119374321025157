<clr-header class="header">
  <div class="branding">
    <span class="module_name title">{{ moduleName }}</span>
    <span class="module_name title">{{ companyName }}</span>
  </div>
  <ng-container *ngIf="state$ | async as vm">
    <ng-container *ngIf="!isGuest && user">
      <div class="header-actions hide-for-print">
        <div class="user-state">
          <span class="top-hint">Connecté en tant que :</span>
          <span class="user-name connected">
            {{ user.username }}
          </span>
        </div>
        <div class="user-actions">
          <button
            [disabled]="performingAction"
            (click)="actionLogout($event, vm?.logoutMessage)"
            class="btn lik-btn primary-btn"
          >
            <clr-icon shape="logout"></clr-icon>
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</clr-header>
