import { NgxClrSmartGridModule } from '@azlabsjs/ngx-clr-smart-grid';
import {
  DEFAULT_CURRENCY_CODE,
  Injector,
  NgModule,
  LOCALE_ID,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxSmartFormModule } from '@azlabsjs/ngx-smart-form';
import { environment } from 'src/environments/environment';
import { SharedModule } from './shared.module';
import {
  AppUIStateProvider,
  UIStateModule,
  UIStateStatusCode,
} from './views/partial/ui-state/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { LocalStrategy, StrategyBasedAuthModule } from './views/login/core';
import {
  AUTH_ACTION_HANDLERS,
  AUTH_CLIENT_CONFIG,
  AUTH_SERVICE,
  AUTH_SERVICE_CONFIG,
  AuthStrategies,
} from './views/login/constants';
import { Router } from '@angular/router';
import { first, firstValueFrom, interval, map, tap } from 'rxjs';
import { DOCUMENT_SESSION_STORAGE, StorageModule } from '@azlabsjs/ngx-storage';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import { HeaderInterceptorProvider } from './views/dashboard/header.interceptor';
import { HTTPResponse } from '@azlabsjs/requests';
registerLocaleData(localeFr, 'fr', localeFrExtra);
import { NgxIntlTelInputModule } from '@azlabsjs/ngx-intl-tel-input';
import { AzlCachePipe, NgxAzlCacheModule } from '@azlabsjs/ngx-azl-cache';
import { MemberPipe } from './views/dashboard/pipes/member.pipe';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    UIStateModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'fr',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NgxSmartFormModule.forRoot({
      serverConfigs: {
        api: {
          host: environment.forms.host,
          bindings: environment.forms.endpoints.bindingsPath,
        },
      },
      formsAssets: '/assets/resources/jsonforms.json',
      submitRequest: {
        interceptorFactory: (injector: Injector) => {
          return async (request, next) => {
            const authToken = await firstValueFrom(
              injector
                .get(AUTH_SERVICE)
                .signInState$.pipe(map((state) => state?.authToken))
            );
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${authToken}`,
              },
            });
            return next(request);
          };
        },
      },
      optionsRequest: {
        interceptorFactory: (injector: Injector) => {
          return async (request, next) => {
            const authToken = await firstValueFrom(
              injector
                .get(AUTH_SERVICE)
                .signInState$.pipe(map((state) => state?.authToken))
            );
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${authToken}`,
              },
            });
            let response = await (next(request) as Promise<HTTPResponse>);
            const body =
              (response.body as Record<string, any>)['data'] ?? response.body;
            response = response.clone({
              setBody:
                (response.body as Record<string, any>)['data'] ?? response.body,
            });
            return response;
          };
        },
      },
    }),
    NgxClrSmartGridModule.forRoot({
      pipeTransformMap: {
        azlcache: AzlCachePipe,
        member: MemberPipe
      },
    }),
    UIStateModule.forRoot(),
    StrategyBasedAuthModule.forRoot(
      {
        provide: AUTH_ACTION_HANDLERS,
        useFactory: (uiState: AppUIStateProvider, router: Router) => {
          return {
            onAuthenticationFailure: () => {
              uiState.endAction(
                'login.authenticationFailed',
                UIStateStatusCode.BAD
              );
            },
            onAuthenticaltionSuccessful: () => {
              uiState.endAction(
                'login.successful',
                UIStateStatusCode.AUTHENTICATED
              );
            },
            onPerformingAction: () => {
              uiState.startAction();
            },
            onError: () => {
              uiState.endAction('', UIStateStatusCode.ERROR);
            },
            onLogout: () => {
              interval(300)
                .pipe(
                  first(),
                  tap(() => {
                    router.navigate(['login']);
                    uiState.endAction();
                  })
                )
                .subscribe();
            },
          };
        },

        deps: [AppUIStateProvider, Router],
      },
      {
        provide: AUTH_SERVICE_CONFIG,
        useFactory: (client: HttpClient, storage: Storage) => ({
          strategies: [
            {
              id: AuthStrategies.LOCAL,
              strategy: new LocalStrategy(
                client,
                environment.auth.host,
                storage,
                {
                  users: 'api/v2/user',
                  signIn: 'api/v2/login',
                  signOut: 'api/v2/logout',
                }
              ),
            },
          ],
          autoLogin: true,
        }),
        deps: [HttpClient, DOCUMENT_SESSION_STORAGE],
      }
    ),
    StorageModule.forRoot({
      secret: 'SECRET', // Offrir la possibilité de charger cette configuration depuis le fichier d'environment
      prefix: 'BIOSECU_SUPPORT', // Offrir la possibilité de charger cette configuration depuis le fichier d'environment
    }),
    NgxIntlTelInputModule.forRoot(),
    NgxAzlCacheModule.forRoot({
      debug: false,
      router: {
        autoload: true,
        slicesFactory: (injector: Injector) => {
          return {
            ['dashboard']: [
              // {
              //   endpoint: 'https://coopecclients.lik.tg/api/members',
              //   key: 'app.members',
              // },
              {
                endpoint: 'https://coopec-credits.lik.tg/api/credit-types/',
                key: 'app.credit_types',
              },
              {
                endpoint:
                  'https://coopec-credits.lik.tg/api/payement-sequences',
                key: 'app.payment_sequences',
              },
              {
                endpoint: 'https://coopec-credits.lik.tg/api/refund-modalities',
                key: 'app.refund_modalities',
              },
              {
                endpoint: 'https://coopecsettings.lik.tg/api/activity-sectors/',
                key: 'app.activity_sectors',
              },
              {
                endpoint: 'https://coopec-credits.lik.tg/api/insurances',
                key: 'app.insurances',
              },
            ],
          };
        },
      },
    }),
  ],
  providers: [
    HeaderInterceptorProvider,
    {
      provide: AUTH_CLIENT_CONFIG,
      useValue: {
        id: environment.auth.clientID,
        secret: environment.auth.clientSecret,
      },
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
    { provide: LOCALE_ID, useValue: 'fr' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
