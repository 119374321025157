<ng-container *ngIf="uiState$ | async as state">
  <app-ui-notification
    [props]="{
      message: state.uiMessage,
      status: state.status,
      hasError: state.hasError,
      hidden: state.hidden
    }"
  >
    <app-online-state-monitoring></app-online-state-monitoring>
  </app-ui-notification>

  <app-progress-bar
    [performingAction]="state.performingAction"
    [indeterminate]="true"
    [overlay]="true"
  >
  </app-progress-bar>
</ng-container>

<app-components-loading
  *ngIf="showComponentLoadingDirective"
></app-components-loading>

<router-outlet></router-outlet>
