<div class="clr-row">
  <div class="clr-col-lg-3 clr-col-md-3 clr-col-12" *ngFor="let item of cards">
    <div [class]="'card stat-card ' + item?.class">
      <div class="card-block">
        <div class="card-title">{{ item?.title }}</div>
        <div class="card-text" *ngIf="item?.content">
          <div class="card-subtitle" *ngIf="item?.subtitle">{{ item?.subtitle }}</div>
          {{ item?.content }}
        </div>
      </div>
      <div class="card-footer">
        <ng-container *ngFor="let action of item?.actions">
          <button
            [ngClass]="action?.class ? action?.class : 'btn btn-sm btn-link'"
            [routerLink]="action?.route"
          >
            <clr-icon
              *ngIf="action?.routeIcon"
              [attr.shape]="action?.routeIcon"
            ></clr-icon>
            {{ action?.label }}
          </button></ng-container
        >
      </div>
    </div>
  </div>
</div>
