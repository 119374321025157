export const TRANSLATIONS = [
  "login.username",
  "login.password",
  "login.rememberMe",
  "login.authenticationFailed",
  "login.authenticating",
  "invalidRequestParams",
  "serverRequestFailed",
  "unauthorizedAccess",
  "sessionExpired",
];
