import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProvidesQuery } from '@azlabsjs/ngx-query';
import { QueryProviderType } from '@azlabsjs/rx-query';
import { Observable } from 'rxjs';

@Injectable()
@ProvidesQuery({
  observe: 'body',
  refetchInterval: 1000 * 60 * 10,
})
export class Querycacher
  implements
    QueryProviderType<[string, Record<string, any> | [string, string]]>
{
  constructor(private httpClient: HttpClient) {}
  query(url: string, params?: Record<string, any>): Observable<any> {
    return this.httpClient.get(url, { params }) as Observable<Object>;
  }
}
