<div class="searcher">
  <ng-container *ngIf="form$ | async as vm">
    <ngx-smart-form
      #formvalue
      [form]="vm"
      [submitable]="true"
      (submit)="onSubmit($event)"
      (readyState)="onReadyState($event)"
    >
      <ng-template #submitButton let-handler>
        <button class="btn btn-primary" (click)="handler($event)">
          <clr-icon shape="search"></clr-icon>
          RECHERCHER
        </button>
      </ng-template>
    </ngx-smart-form>
  </ng-container>
</div>
