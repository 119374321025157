// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  forms: {
    host: 'https://coopeccredits.lik.tg/api',
    endpoints: {
      bindingsPath: 'api/control-bindings',
    },
    clientHost: 'https://coopecclients.lik.tg/api',
    settingHost: 'https://coopecsettings.lik.tg/api',
    requestHost: 'https://coopecdesk.lik.tg/api'
  },
  auth: {
    host: 'https://auth.lik.tg/',
    clientID: '859782E1-9A2F-49A4-9D42-B59A78E520FB',
    clientSecret:
      'wJa60mWPUK2W8AycfziCrWeMWSus4HLAoSV9cq2qb6FTMlmEudoItlbUHwdUw15peIXmF2b2q2LwCYSO0fvvgQ',
  },
  module: {
    name: 'CREDITS'
  },
  company : {
    appLogo: "assets/images/coopectrassto.png",
    name: "COOPECTRASSTO"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
